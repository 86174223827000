import { useTranslation } from "react-i18next"
import Banner from "../components/Banner"
import ProjectsList from "../components/ProjectsList"

const Projects = () => {
  const { t } = useTranslation();

  return (
    <div className="projects-page">
      <Banner title="projects" />
      <section className="project-page container pb-0 rpy-50 text-center">
        <div className="section-title mb-45 rmb-0 r text-center">
          <span className="sub-title">{t('projects_subtitle')}</span>
          <h2>{t('projects_title')}</h2>
        </div>
        <ProjectsList />
      </section>
    </div>
  )
}
export default Projects