import Banner from "../components/Banner"
import { useTranslation } from "react-i18next"
import Pricing from "../components/Pricing";
import SmallBusinessSteps from "../components/smallBusiness/SmallBusinessSteps";
import SmallBusinessServices from "../components/smallBusiness/SmallBusinessServices"
import SmallBusinessMaintenance from "../components/smallBusiness/SmallBusinessMaintenance";

const SmallBusinesses = () => {
  const { t } = useTranslation();

  const services = ['sb_service1', 'sb_service2', 'sb_service3', 'sb_service2']

  return (
    <>
      <Banner title='Small businesses' bannerClass="small-business" />
      <section className="services-area-three overflow-hidden pt-130 rpt-100 pb-100 rpb-70 rel z-1">
        <div className="container">
          <div className="row gap-100 align-items-center">
            <div className="col-lg-5">
              <div className="services-content-three mb-30 rmb-65 wow fadeInUp delay-0-2s">
                <div className="section-title mb-30">
                  {/* <span className="sub-title mb-15">Amazing Services</span> */}
                  <h2>{t('small_businesses_title')}</h2>
                </div>
                <p>{t('small_businesses_text')}</p>
                <ul className="list-style-one pt-3">
                  {services.map((item, key) => (
                    <li>
                      <div className="d-flex align-items-center">
                        <i className="bi bi-check-circle-fill me-2"></i>
                        <div>{t(item)}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-7">
              <img src="/images/small_business.png" alt=""></img>
            </div>
          </div>
        </div>
        <div className="servcies-bg-shape">
          <img src="assets/images/shapes/services-bg-shape.png" alt="Shape" />
        </div>
      </section>
      <SmallBusinessServices />
      <SmallBusinessSteps />
      <SmallBusinessMaintenance />
      <Pricing />
    </>
  )
}
export default SmallBusinesses