import { useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import menu from "../../data/menuData.json"

const MobileMenu = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const active = (value) => setActiveMenu(value === activeMenu ? null : value);
  const activeSubMenu = (value) => value == activeMenu ? { display: "block" } : { display: "none" };
  const ref = useRef();

  const { t } = useTranslation();

  const changeLanguage = (language) => {
    if (language === "sr") {
      i18n.changeLanguage("sr");
    }
    else {
      i18n.changeLanguage("eng");
    }
  }

  const closeMenu = () => {
    const menu = ref.current.querySelector('.navbar-collapse.mobile');
    menu.classList.remove('show')
  }

  return (
    <nav className="main-menu navbar-expand-lg mobile-menu d-block d-lg-none" ref={ref}>
      <Accordion>
        <div className="navbar-header">
          <div className="mobile-logo">
            <div className="logo">
              <Link to="/">
                <img src="/images/logo/appster3.svg" alt="Logo" title="Logo" />
              </Link>
            </div>
          </div>
          <div className="menu-btns">
            <div className="menu-btns">
              <div className="srb-icon">
                <img src="/images/icons/serbian.png" alt="" onClick={() => changeLanguage('sr')} role="button"></img>
                <div className={`icon-overlay ${i18n.language === "sr" ? "disabled" : ""}`}></div>
              </div>
              <div className="eng-icon">
                <img src="/images/icons/english.png" alt="" onClick={() => changeLanguage('eng')} role="button"></img>
                <div className={`icon-overlay ${i18n.language === "eng" ? "disabled" : ""}`}></div>
              </div>
            </div>
          </div>
          <Accordion.Toggle
            as={"button"}
            type="button"
            className="navbar-toggle"
            eventKey="collapse"
            data-bs-target=".navbar-collapse"
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </Accordion.Toggle>
        </div>
        <Accordion.Collapse
          eventKey="collapse"
          className="navbar-collapse mobile clearfix"
        >
          <ul className="navigation clearfix">
            {menu.map(item => (
              <li className={item.submenu ? "dropdown" : ""} key={item.id}>
                {item.submenu ? (
                  <a href="#" onClick={() => active(item.href)}>{item.title}</a>
                ) : (
                  <Link to={item.href} onClick={() => { active(item.title); closeMenu(); }} className="text-decoration-none">
                    {t(item.title)}
                  </Link>
                )}
                {item.submenu && (
                  <>
                    <ul style={activeSubMenu(item.title)} className="p-0 m-0">
                      {item.submenu.map((i, key) => (
                        <li key={key}>
                          <Link to={i.href}>{t(i.title)}</Link>
                        </li>
                      ))}
                    </ul>
                    <div className="dropdown-btn" onClick={() => active(item.title)}>
                      <span className="fas fa-chevron-down" />
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        </Accordion.Collapse>
      </Accordion>
    </nav >
  );
};
export default MobileMenu