import { Link } from "react-router-dom"
import Banner from "../components/Banner"
import { useTranslation } from "react-i18next"
import workWithUs from "../data/workWithUsData.json"
import positions from "../data/openedPositionsData.json"
import JobApplyForm from "../components/JobApplyForm"

const Careers = () => {
  const { t } = useTranslation();

  return (
    <div className="careers-page">
      <Banner title="careers" />
      <section className="about-area-five py-130 rpt-0 rpb-65 rel z-1">
        <div className="container">
          <div className="row align-items-center gap-100">
            <div className="col-lg-6">
              <div className="about-content mt-55 rel z-1 wow fadeInLeft delay-0-2s">
                <div className="section-title mb-60 rmb-40 text-center">
                  <span className="sub-title mb-15">{t('be_part_of_team')}</span>
                  <h2 dangerouslySetInnerHTML={{ __html: t('why_work') }}></h2>
                </div>
                <div className="row gap-40">
                  {workWithUs.map((item, key) => (
                    <div className="col-6">
                      <div className="service-item style-three text-center text-md-start">
                        <div className="icon">
                          <img src={`images/icons/${item.icon}.png`} alt=""></img>
                        </div>
                        <h4 className="d-none d-md-block">{t(item.title)}</h4>
                        <h6 className="d-block d-md-none">{t(item.title)}</h6>
                        <p>{t(item.text)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-2 order-md-1">
              <div className="about-five-images mt-55 rel z-1 wow fadeInRight delay-0-2s">
                <img src="/images/careers-img.png" className="w-100 my-auto" alt="About" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-area-six py-100 rpb-70 rpt-50 rel z-1">
        <div className="container">
          <div className="section-title mb-60 rmb-40 text-center">
            <span className="sub-title mb-15">{t('join_the_team')}</span>
            <h2>{t('opened_positions')}</h2>
          </div>
          <div className="row justify-content-center">
            {positions.map((item, key) => (
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="service-item-six wow fadeInUp delay-0-2s text-center">
                  <div className="icon">
                    <i className={item.icon} />
                  </div>
                  <h4>{item.title}</h4>
                  <Link to={`/job-apply/${item.id}`}>
                    <a className={`theme-btn style-two`}> {t('apply')}<i className="fas fa-long-arrow-right" /></a>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}
export default Careers