import DeskTopMenu from "./DeskTopMenu";
import MobileMenu from "./MobileMenu";

const Menu = () => {
  return (
    <>
      <DeskTopMenu />
      <MobileMenu />
    </>
  );
};
export default Menu