import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "i18next";
import menu from "../../data/menuData.json"
import { Fragment } from "react";

const DeskTopMenu = () => {
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    if (language === "sr") {
      i18n.changeLanguage("sr");
    }
    else {
      i18n.changeLanguage("eng");
    }
  }

  return (
    <nav className="main-menu navbar-expand-lg desktop-menu d-none d-lg-flex align-items-center">
      <div className="logo-outer d-none d-md-block">
        <div className="logo">
          <Link to="/">
            <img src="/images/logo/appster3.svg" alt="Logo" title="Logo" />
          </Link>
        </div>
      </div>
      <div className="navbar-collapse collapse clearfix justify-content-end me-5">
        <ul className="navigation">
          {menu.map(item => (
            <Fragment key={item.id}>
              {item.title !== "contact_us" && (
                <>
                  <li className={`${item.submenu ? "dropdown" : ""}`}>
                  {item.submenu ?  <a href="#">{t(item.title)}</a> : <Link to={item.href} className="text-decoration-none">{t(item.title)}</Link>}
                    {item.submenu && (
                      <>
                        <ul className="p-0">
                          {item.submenu.map((i, key) => (
                            <li key={key}>
                              <Link to={i.href}>{t(i.title)}</Link>
                            </li>
                          ))}
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-chevron-down" />
                        </div>
                      </>
                    )}
                  </li>
                </>
              )}
            </Fragment>
          ))}
        </ul>
      </div>
      <div className="menu-btns">
        <div className="menu-btns">
          <div className="srb-icon">
            <img src="/images/icons/serbian.png" alt="" onClick={() => changeLanguage('sr')} role="button"></img>
            <div className={`icon-overlay ${i18n.language === "sr" ? "disabled" : ""}`}></div>
          </div>
          <div className="eng-icon">
            <img src="/images/icons/english.png" alt="" onClick={() => changeLanguage('eng')} role="button"></img>
            <div className={`icon-overlay ${i18n.language === "eng" ? "disabled" : ""}`}></div>
          </div>
          <Link to="/contact" className="theme-btn p-0 px-3 py-3">{t('contact_us').toUpperCase()}</Link>
        </div>
      </div>
    </nav>
  );
};
export default DeskTopMenu