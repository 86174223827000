import { useTranslation } from 'react-i18next';
import smallBusinessStepsData from '../../data/smallBusinessStepsData.json'

const SmallBusinessSteps = () => {
  const { t } = useTranslation();

  return (
    <section className="statistics-area-four text-white bgs-cover pt-30 pb-20" style={{ backgroundImage: "url(assets/images/background/statistics-three.jpg)" }}>
      <div className="container">
        <div>
          <div>
            <h2 className="text-center mb-5">{t('steps_title')}</h2>
            <div className="row">
              {smallBusinessStepsData.map(item => (
                <div className="col" key={item.id}>
                  <div className="counter-item counter-text-wrap wow fadeInDown delay-0-3s text-center">
                    <div className="number mx-auto">{item.id}</div>
                    <span className="counter-title">{t(item.text)}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default SmallBusinessSteps