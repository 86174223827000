import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="main-footer footer-white rel z-1">
      <div className="footer-cta-wrap">
        <div className="container">
          <div className="footer-cta-inner bgs-cover row align-items-center" style={{ backgroundImage: "url(images/footer-cta-bg.webp)" }}>
            <div className="section-title wow fadeInLeft delay-0-2s col-12 col-md">
              <span className="sub-title1">{t('like_our_company')}</span>
              <h6 className="footer-conternt" dangerouslySetInnerHTML={{ __html: t('check_appster_solutions') }}></h6>
            </div>
            <div className="add-container col-12 col-md-auto">
              <Link to="https://appstersolutions.com/" target="_blank">
                <img src="/images/logo/appstersolutions.svg" alt="" className="mx-auto"></img>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-xl-3 col-sm-6 order-xl-2 text-center">
            <div className="footer-widget widget_newsletter wow fadeInUp delay-0-6s">
              <h4 className="footer-title">{t('contact_us')}</h4>
              <div className="d-flex justify-content-center">
                <i className="bi bi-envelope-fill me-2"></i>
                <p>info@appsterdevelopment.com</p>
              </div>
              <div className="d-flex justify-content-center">
                <i className="bi bi-geo-alt-fill me-2"></i>
                <p>Mihaila Avramovića 52b</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 text-center">
            <div className="footer-widget widget_about wow fadeInUp delay-0-2s">
              <div className="footer-logo mb-30">
                <Link to="/"><img src="/images/logo/appster3.svg" alt="Logo" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;