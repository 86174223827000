import { useTranslation } from "react-i18next";
import Banner from "../components/Banner";
import ContactForm from "../components/ContactForm/ContactForm";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="contact-page">
      <Banner title="contact_us" />
      <section className="contact-page-info pt-130 rpt-0 pb-100 rpb-50 rel z-1">
        <div className="container">
          <div className="row text-center mb-35 justify-content-center wow fadeInUp delay-0-2s">
            <div className="col-xl-8 col-lg-10">
            </div>
          </div>
          <div className="contact-page-wrap sec-mar">
            <div className="container">
              <div className="row g-lg-4 gy-5">
                <div className="col-lg-6">
                  <div className="contact-content">
                    <h2 className=" text-center text-md-start">{t('have_a_project')}</h2>
                    <div dangerouslySetInnerHTML={{ __html: t('contact_text') }} className=" text-center text-md-start"></div>
                    <div className="informations">
                      <div className="single-info d-flex align-items-center">
                        <div className="icon">
                          <i className="fas fa-map-marker-alt" />
                        </div>
                        <div className="info">
                          <p>Mihaila Avramovića 52b - Belgrade 11000</p>
                        </div>
                      </div>
                      <div className="single-info d-flex align-items-center">
                        <div className="icon">
                          <i className="far fa-envelope" />
                        </div>
                        <div className="info">
                          <a href="mailto: info@example.com">info@appsterdevelopment.com</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-page-map wow fadeInUp delay-0-2s">
        <div className="our-location">
          <iframe src="https://maps.google.com/maps?q=44.761760, 20.461315&z=15&output=embed" width="100%" height="600" frameborder="0" ></iframe>
        </div>
      </section>
    </div>
  );
};
export default Contact;