import { useTranslation } from "react-i18next"
import clients from "../data/clientsData.json"
import Card from "./Card";
import Carroussel from "./Carousel";
import Carousel from "react-multi-carousel";
import Marquee from "react-fast-marquee";

const OurClients = () => {
  const { t } = useTranslation();
  let cards = clients.map((item, key) => {
    return (
      {
        key: key,
        content: (
          <Card
            image={`images/clients/${item.image}.png`}
            name={item.name}
            text={item.text}
            href={item.href}
          />
        )
      }
    )
  })

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1400, min: 993 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 993, min: 0 },
      items: 1
    }
  };

  return (
    <section className="testimonials-three-area py-130 rpt-50 rpb-0">
      <div className="container">
        <div className="testimonials-three-wrap wow fadeInRight delay-0-2s">
          <div className="section-title mb-45 rmb-0 r text-center">
            <span className="sub-title">{t('our_clients_subtitle')}</span>
            <h2>{t('our_clients')}</h2>
          </div>
          <div className="mt-100 rmt-0 overflow-hidden">
            <Carroussel
              cards={cards}
              height="500px"
              width="100%"
              margin="0 auto"
              offset={2}
              showArrows={true}
            />
            <Carousel responsive={responsive} className="mt-150" autoPlay>
              {clients.map((item, key) => (
                <Card
                  image={`/images/clients/${item.image}.png`}
                  name={item.name}
                  text={item.text}
                  href={item.href}
                />
              ))}
            </Carousel>
            <div className="mt-200">
              <Marquee className='marquee_text' autoFill={true} pauseOnHover>
                {clients.map((item, key) => (
                  <Card
                    image={`/images/clients/${item.image}.png`}
                    name={item.name}
                    text={item.text}
                    href={item.href}
                  />
                ))}
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default OurClients