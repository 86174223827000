import { Link } from "react-router-dom"
import Banner from "../components/Banner"
import servicesData from "../data/servicesData.json"
import { useTranslation } from "react-i18next"
import WorkProcess from "../components/WorkProcess"
import Slider from "react-slick"
import { projectSliderActive } from "../components/sliderProps"
import { useContext, useEffect, useRef } from "react"
import ContactFormInfo from "../components/ContactForm/ContactFormInfo"
import { AppsterContext } from "../context"

const Outsourcing = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const services = servicesData.filter(item => item.type === "outsourcing");
  const { state } = useContext(AppsterContext);

  const handleText = (item, key) => {
    const textElement = ref.current.querySelector(`.card-text${key}`);
    const buttonElement = ref.current.querySelector(`.btn-box${key}`);
    const btnIcon = buttonElement.querySelector('i');

    if (textElement.classList.contains('truncate')) {
      textElement.classList.remove('truncate')
      textElement.innerHTML = t(item.text)
      buttonElement.querySelector('p').innerHTML = t('show_less')
      btnIcon.style.transform = 'rotate(180deg)'
      btnIcon.style.marginBottom = '16px'
    } else {
      textElement.classList.add('truncate');
      buttonElement.querySelector('p').innerHTML = t('show_more')
      btnIcon.style.transform = 'rotate(360deg)'
      btnIcon.style.marginBottom = '0px'
      textElement.innerHTML = t(`${item.text}_short`)
    }
  }

  useEffect(() => {
    if(window.innerWidth > 767) return;
    setTimeout(() => {
      switch (state.product) {
        case "os-development":
          ref.current.querySelector('.product-card1').scrollIntoView({ behavior: 'smooth' });
          break;
        case "pr-development":
          ref.current.querySelector('.product-card2').scrollIntoView({ behavior: 'smooth' });
          break;
        case "it-consulting":
          ref.current.querySelector('.product-card3').scrollIntoView({ behavior: 'smooth' });
          break;

        default:
          break;
      }
    }, 300)


  }, [window])

  return (
    <div className="outsourcing-page">
      <Banner title="our_services" bannerClass="services"/>
      <section className="services-area-three overflow-hidden pt-130 rpt-50 pb-100 rpb-50">
        <div className="container">
          <div className="row gap-100 align-items-center">
            <div className="col-lg-7">
              <div className="services-content-three text-center text-md-start mb-30 rmb-65 wow fadeInUp delay-0-2s">
                <div className="section-title mb-30 ">
                  <span className="sub-title mb-15">{t('our_services')}</span>
                  <h2>{t('services_title')}</h2>
                </div>
                <p dangerouslySetInnerHTML={{ __html: t('services_text') }}></p>
                <Link to="/contact" className="theme-btn text-white rounded mt-4">{t('contact_us')}</Link>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="row" ref={ref}>
                {services.map((item, key) => (
                  <div className={`col-12 col-md product-card${key + 1}`} key={key}>
                    <div className={`feature-item style-two wow fadeInUp delay-0-2s p-4 mb-3`}>
                      <div className="icon">
                        <img src={`/images/icons/${item.icon}.png`} alt=""></img>
                      </div>
                      <h4 className="d-none d-md-block">{t(item.title)}</h4>
                      <h5 className="d-block d-md-none">{t(item.title)}</h5>
                      <div className="truncate-no-ellipsis">
                        <p dangerouslySetInnerHTML={{ __html: t(`${item.text}_short`) }} className={`card-text${key + 1} d-md-none truncate`}></p>
                      </div>
                      <div className={`service-chevron btn-box${key + 1} d-flex justify-content-center d-md-none`} onClick={() => handleText(item, key + 1)}>
                        <p className="me-2">{t('show_more')}</p>
                        <i className={`q${key + 1} bi bi-chevron-double-down`}></i>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="servcies-bg-shape">
          <img src="assets/images/shapes/services-bg-shape.png" alt="Shape" />
        </div>
      </section>
      <section className="service-details-area bgc-lighter">
        <div className="container">
          <div className="service-slider d-none d-md-block">
            <Slider {...projectSliderActive} className="project-slider-active">
              {services.map((item, key) => (
                <div className="project-slider-item d-md-flex" key={key}>
                  <div className={`video ${(key + 1) % 2 === 0 ? "order-2" : ""}`}>
                    <img src={`/images/services/${item.image}.webp`} alt="" />
                  </div>
                  <div className="content">
                    <h3 className="mb-4">{t(item.title)}</h3>
                    <p dangerouslySetInnerHTML={{ __html: t(item.text) }}></p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="rpt-50">
          <WorkProcess />
        </div>
      </section>
      <ContactFormInfo />
    </div>
  )
}
export default Outsourcing