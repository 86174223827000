import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import erpClients from "../../data/erpClientsData"

const ErpCLients = () => {
  const { t } = useTranslation();
  
  return(
    <section className="partners-area-two pt-80 pb-50 rel z-1">
        <div className="container">
          <div className="section-title text-white text-center mb-50 wow fadeInUp delay-0-2s">
            <span className="sub-title mb-15">{t('our_clients')}</span>
            <h2>{t('erp_users')}</h2>
          </div>
          <div className="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2 justify-content-center">
            {erpClients.map(item => (
              <div className="col" key={item.id}>
                <Link to={item.href} target="_blank" className="partner-item wow fadeInUp delay-0-3s rounded">
                  <img src={`/images/erp-clients/${item.image}.png`} alt="" />
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="partners-shapes">
          <img
            className="left-shape"
            src="assets/images/partners/partner-shape-left.png"
            alt="Shape"
          />
          <img
            className="right-shape"
            src="assets/images/partners/partner-shape-right.png"
            alt="Shape"
          />
        </div>
      </section>
  )
}
export default ErpCLients