import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import erpServices from "../../data/erpModulesData.json"

const ErpModules = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="project-area-two pb-130 pt-80 rpt-50 rpb-50">
        <div className="container gap-wide">
          <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
            <span className="sub-title">{t('erp_moduls')}</span>
            <h2>{t('erp_moduls_title')}</h2>
          </div>
          {/* <div className="row g-1 justify-content-center">
            {erpServices.map((item, key) => (
              <div className="col-xl-3 col-lg-4 col-sm-6" key={key}>
                <div className="project-item wow fadeInUp delay-0-2s">
                  <div className="image">
                    <img
                      src={`/images/erp/${item.image}.png`}
                      alt="Project"
                    />
                  </div>
                  <div className="content">
                    <h3>{t(item.title)}</h3>
                    <p className="module-text" dangerouslySetInnerHTML={{ __html: t(item.shortText) }}></p>
                    <Link to={`/erp/${item.href}`} className="theme-btn text-white rounded">{t('read_more')}</Link>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
          
          <div className="row g-3 g-md-1 justify-content-center">
            {erpServices.map((item, key) => (
              <div className="col-md-6 col-lg-4 col-xl-3" key={item.id}>
                <div class="flip-card w-100">
                  <div class="flip-card-inner">
                    <div className={`project-item wow fadeInUp delay-0-${(key + 1) * 2}s flip-card-front`}>
                      <div className="image">
                        <img src={`/images/erp/${item.image}.png`} className="w-100 d-none d-md-block" alt="Project" />
                        <img src={`/images/erp/${item.imageSmall}.png`} className="w-100 d-md-none" alt="Project" />
                      </div>
                      <div className="content">
                        <h3>{t(item.title)}</h3>
                      </div>
                    </div>
                    <div class="flip-card-back p-4">
                      <p dangerouslySetInnerHTML={{ __html: t(item.text) }} className="content d-flex align-items-center"></p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
export default ErpModules