import React, { createContext, useReducer } from 'react';

export const AppsterContextType = {
  SET_PRODUCT: 'SET_PRODUCT'
}

const InitialState = {
  product: null
}

export const AppsterContext = createContext();

const AppsterReducer = (state, action) => {
  switch (action.type) {
    case AppsterContextType.SET_PRODUCT: {
      return { ...state, product: action.data }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const AppsterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppsterReducer, InitialState)
  const value = { state, dispatch }
  return <AppsterContext.Provider value={value}>{children}</AppsterContext.Provider>
}
