import { useParams } from "react-router-dom"
import Banner from "../components/Banner"
import erpModules from "../data/erpModulesData.json"
import { useTranslation } from "react-i18next";

const ProductDetails = () => {
  const { t } = useTranslation()
  const { id } = useParams();
  const erpModule = erpModules.find(item => item.type === id)

  return (
    <>
      <Banner title={erpModule.title} />
      <section className="project-details-area py-130 rpt-100 pb-60 rpb-30">
        <div className="container">
          <div className="project-details-content">
            <div className="section-title mb-25">
              <h2>{t(erpModule.title)}</h2>
            </div>
            {erpModule.paragraphs[0] && <p dangerouslySetInnerHTML={{ __html: t(erpModule.paragraphs[0])}}></p>}
            {erpModule.images && erpModule.images[0] && (
              <div className="image my-45 text-center">
                <img
                  src={`/images/erp-${erpModule.type}/${erpModule.images[0]}.png`}
                  alt="Project Details"
                />
              </div>
            )}
            {erpModule.paragraphs[1] && <p dangerouslySetInnerHTML={{ __html: t(erpModule.paragraphs[1])}}></p>}
            {erpModule.images && erpModule.images[1] && (
              <div className="image my-45 text-center">
                <img
                  src={`/images/erp-${erpModule.type}/${erpModule.images[1]}.png`}
                  alt="Project Details"
                />
              </div>
            )}
            {erpModule.paragraphs[2] && <p dangerouslySetInnerHTML={{ __html: t(erpModule.paragraphs[2])}}></p>}
            {erpModule.images && erpModule.images[2] && (
              <div className="image my-45 text-center">
                <img
                  src={`/images/erp-${erpModule.type}/${erpModule.images[2]}.png`}
                  alt="Project Details"
                />
              </div>
            )}
            {erpModule.paragraphs[3] && <p dangerouslySetInnerHTML={{ __html: t(erpModule.paragraphs[3])}}></p>}
            {erpModule.images && erpModule.images[3] && (
              <div className="image my-45 text-center">
                <img
                  src={`/images/erp-${erpModule.type}/${erpModule.images[3]}.png`}
                  alt="Project Details"
                />
              </div>
            )}
            {erpModule.paragraphs[4] && <p dangerouslySetInnerHTML={{ __html: t(erpModule.paragraphs[4])}}></p>}
            {erpModule.images && erpModule.images[4] && (
              <div className="image my-45 text-center">
                <img
                  src={`/images/erp-${erpModule.type}/${erpModule.images[4]}.png`}
                  alt="Project Details"
                />
              </div>
            )}
            {erpModule.paragraphs[5] && <p dangerouslySetInnerHTML={{ __html: t(erpModule.paragraphs[5])}}></p>}
            {erpModule.images && erpModule.images[5] && (
              <div className="image my-45 text-center">
                <img
                  src={`/images/erp-${erpModule.type}/${erpModule.images[5]}.png`}
                  alt="Project Details"
                />
              </div>
            )}
            {erpModule.paragraphs[6] && <p dangerouslySetInnerHTML={{ __html: t(erpModule.paragraphs[6])}}></p>}
            {erpModule.images && erpModule.images[6] && (
              <div className="image my-45 text-center">
                <img
                  src={`/images/erp-${erpModule.type}/${erpModule.images[6]}.png`}
                  alt="Project Details"
                />
              </div>
            )}
          </div>
        </div>
      </section>
      {/* Project Details Area end */}
      {/* Next Prev Project start */}
      {/* <div className="next-prev-project pb-80 rpb-50">
        <div className="container">
          <hr />
          <div className="next-prev-service next-prev-project mt-80">
            <div className="next-prev-item wow fadeInLeft delay-0-2s">
              <div className="image">
                <img
                  src="assets/images/projects/project-prev.jpg"
                  alt="Project"
                />
                <Link to="/project-details">
                  <a className="detail-btn">
                    <i className="far fa-angle-left" />
                  </a>
                </Link>
              </div>
              <div className="content">
                <h4>
                  <Link to="project-details">
                    Cyber Security
                  </Link>
                </h4>
                <Link to="/project-details">
                  <a className="category">IT Solutions</a>
                </Link>
              </div>
            </div>
            <Link to="/project-grid">
              <a className="show-all" />
            </Link>
            <div className="next-prev-item wow fadeInRight delay-0-2s">
              <div className="content">
                <h4>
                  <Link to="project-details">
                    UI/UX Strategy
                  </Link>
                </h4>
                <Link to="/project-details">
                  <a className="category">IT Solutions</a>
                </Link>
              </div>
              <div className="image">
                <img
                  src="assets/images/projects/project-next.jpg"
                  alt="Project"
                />
                <Link to="/project-details">
                  <a className="detail-btn">
                    <i className="far fa-angle-right" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}
export default ProductDetails