import { useTranslation } from "react-i18next"

const ErpAbout = () => {
  const { t } = useTranslation();

  return (
    <div className="outsourcing-section sec-mar pt-130 pb-80 rpt-50 rpb-50 rmt-50 animate fadeInLeft" id="home4-about-section">
      <div className="contact-shapes d-none d-md-block">
        <img
          className="shape one"
          src="/images/shape-left.png"
          alt="shape"
        />
        <img
          className="shape two"
          src="/images/shape-right.png"
          alt="shape"
        />
      </div>
      <div className="container">
        <div className="row g-lg-4 gy-5">
          <div className="col-lg-6 wow animate fadeInLeft order-2 order-lg-1 m-0" data-wow-delay="300ms" data-wow-duration="1500ms">
            <div className="about-left-img-wrap">
              <div className="big-img magnetic-item">
                <img className="img-fluid mt-5 mb-5 mb-md-0" src="/images/erp/erp1.png" alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 row align-items-center g-0 wow animate fadeInRight p-2 order-1 order-lg-2" data-wow-delay="500ms" data-wow-duration="1500ms">
            <div className="banner-right-content">
              <div className="section-title-4">
                <div className="section-title mb-30 text-center text-md-left">
                  <span className="sub-title mb-15">{t('what_is_erp_subtitle')}</span>
                  <h2>{t('what_is_erp')}</h2>
                </div>
                <p dangerouslySetInnerHTML={{ __html: t('erp_text') }} className="text-center text-md-start"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bottom rpt-0">
          <div className="row g-lg-4 gy-5">
            <div className="col-lg-6 wow animate fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
              <div className="about-left-content">
                <div className="section-title mb-30 text-center text-md-left rpt-50">
                  <span className="sub-title mb-15">{t('why_erp_subtitle')}</span>
                  <h2>{t('why_erp')}</h2>
                </div>
                <p dangerouslySetInnerHTML={{ __html: t('why_erp_text') }} className="text pb-3 text-center text-md-start"></p>
                <div className="about-feature rpt-0">
                  <ul>
                    <li class="d-flex align-items-center mt-2"><i class="bi bi-check-circle-fill me-2 mb-3"></i><p>{t('erp_point1')}</p></li>
                    <li class="d-flex align-items-center"><i class="bi bi-check-circle-fill me-2 mb-3"></i><p>{t('erp_point2')}</p></li>
                    <li class="d-flex align-items-center"><i class="bi bi-check-circle-fill me-2 mb-3"></i><p>{t('erp_point3')}</p></li>
                    <li class="d-flex align-items-center"><i class="bi bi-check-circle-fill me-2 mb-3"></i><p>{t('erp_point4')}</p></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow animate fadeInRight" data-wow-delay="500ms" data-wow-duration="1500ms">
              <div className="about-right-img d-flex justify-content-center  magnetic-item">
                <img className="img-fluid" src="/images/erp/erp2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ErpAbout