import WorkProcess from "../components/WorkProcess"
import services from "../data/servicesData.json"
import { useTranslation } from "react-i18next";
import Technologies from "../components/Technologies";
import ProjectsList from "../components/ProjectsList";
import OurClients from "../components/OurClients";
import { Link } from "react-router-dom";
import ContactFormInfo from "../components/ContactForm/ContactFormInfo";
import { useContext } from "react";
import { AppsterContext, AppsterContextType } from "../context";

const videos = {
  desktop: "/video/intro1.mp4",
  mobile: "/video/intro2.mp4"
}

const Home = () => {
  const { t } = useTranslation();
  const getDeviceVideo = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) ? videos.mobile : videos.desktop;
  const { dispatch } = useContext(AppsterContext);

  const handleSelectedProduct = (product) => {
    dispatch({
      type: AppsterContextType.SET_PRODUCT,
      data: product,
    });
  }


  return (
    <div className="home-page">
      <section>
        <video
          className="w-100"
          src={getDeviceVideo}
          type="video/mp4"
          playsInline
          autoPlay
          muted
          loop
        />
      </section>
      <section className="feature-area-two rel z-1">
        <div className="container">
          <div className="row justify-content-center">
            {services.map((item, key) => (
              <Link to={item.href} onClick={() => handleSelectedProduct(item.product)} className="col-6 col-xl-2 mb-2 text-decoration-none home-card-text" key={key}>
                <div className="h-100">
                  <div className={`feature-item style-two home delay-0-2s d-flex flex-column justify-content-center align-items-center h-100 px-5 px-sm-3 px-lg-5`}>
                    <div className="icon">
                      <img src={`/images/icons/${item.icon}.png`} alt=""></img>
                    </div>
                    <Link to={item.href} onClick={() => handleSelectedProduct(item.product)} className="text-decoration-none home-card-text">
                      <div className="title">
                        <h5 className="d-none d-md-block">{t(item.title)}</h5>
                        <h6 className="d-block d-md-none">{t(item.title)}</h6>
                      </div>
                    </Link>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
      <section className="customer-satisfaction-area rel z-1">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-5 col-lg-6">
              <div className="cs-content-part rmb-35 wow fadeInLeft text-center text-md-start delay-0-2s">
                <div className="section-title mb-15 mt-3">
                  <span className="sub-title">{t('about_us')}</span>
                  <h2>{t('about_us_title_home')}</h2>
                </div>
                <div dangerouslySetInnerHTML={{ __html: t('about_us_text_home') }}></div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cs-image-part mb-30 wow fadeInRight delay-0-2s p-0">
                <div className="image p-0 mx-auto">
                  <img src="/images/home-about.webp" alt="" />
                  <img src="/images/home-about1.webp" alt="" className="home-about-image-two" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="why-choose-shapes">
          <img
            className="shape one"
            src="/images/shape-right.png"
            alt="Shape"
          />
          <img
            className="shape two"
            src="/images/shape-left.png"
            alt="Shape"
          />
        </div>
      </section>
      <Technologies />
      <section className="project-area overflow-hidden bgc-lighter pt-130 rpt-50 rel z-1">
        <div className="container home-project-list">
          <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
            <span className="sub-title">{t('projects_subtitle')}</span>
            <h2>{t('projects_title')}</h2>
          </div>
          <ProjectsList />
        </div>
        <div className="project-shapes">
          <img
            className="shape one"
            src="/images/shape-left.png"
            alt="shape"
          />
          <img
            className="shape two"
            src="/images/shape-right.png"
            alt="shape"
          />
        </div>
      </section>
      <section className="services-area text-white pb-10 rel z-1 pt-130 rpt-50">
        <div className="container">
          <WorkProcess />
        </div>
      </section>
      {/* <OurClients /> */}
      <section>
        <ContactFormInfo />
        <div className="contact-shapes">
          <img
            className="shape one"
            src="/images/shape-left.png"
            alt="shape"
          />
          <img
            className="shape two"
            src="/images/shape-right.png"
            alt="shape"
          />
        </div>
      </section>
    </div>
  )
}
export default Home