import { useTranslation } from 'react-i18next'
import workProcessData from '../data/workProcess.json'
import { Fragment } from 'react';

const WorkProcess = () => {
  const { t } = useTranslation();

  return (
    <section className="work-process-area pb-100 rpt-0 rpb-70 rel z-1">
      <div className="section-title text-center wow fadeInUp delay-0-2s">
        <span className="sub-title text-white">{t('our_process')}</span>
        <h2 className="text-white">{t('work_process_title_home')}</h2>
      </div>
      <div className="work-process-line text-center d-none d-md-block">
        <img src="/images/process-line.png" alt="line" />
      </div>
      <div className="container">
        <div className="row g-0 justify-content-center">
          {workProcessData.map(item => (
            <Fragment key={item.id}>
              <div className="col d-none d-lg-block">
                <div className={`work-process-item ${item.margin ? 'mt-' + item.margin : ''} wow fadeInUp delay-0-${item.id % 2 === 0 ? 2 : 6}s`}>
                  <div className="number"><i className={`flaticon-${item.icon}`}></i></div>
                  <div className="content me-3">
                    <h4>{t(item.title)}</h4>
                    <p>{t(item.text)}</p>
                  </div>
                </div>
              </div>
              <div className={` d-block d-lg-none row`}>
                <div className={`work-process-item mobile align-items-center ${item.margin ? 'mt-' + item.margin : ''} wow fadeInUp delay-0-2s`}>
                  <div className={`number mobile col-auto ${item.id % 2 === 0 ? "me-2" : "me-2"}`}><i className={`flaticon-${item.icon}`}></i></div>
                  <div className="content col">
                    <h4>{t(item.title)}</h4>
                    <p>{t(item.text)}</p>
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}
export default WorkProcess