import { Nav, Tab } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import siteMaintenance from '../../data/SiteMaintenanceData.json'

const SmallBusinessMaintenance = () => {
  const { t } = useTranslation();

  return (
    <section className="about-area-four pt-25 rpt-0 rel z-2">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="about-four-image rel z-1 mb-65 wow fadeInRight delay-0-2s">
              <div className="about-circle">
                <img
                  src="assets/images/about/about-circle.png"
                  alt="Circle"
                />
                <img
                  className="text"
                  src="assets/images/about/about-circle-text.png"
                  alt="Circle Text"
                />
              </div>
              <div className="image">
                <img src="assets/images/about/about-four.jpg" alt="About" />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-10 pt-50">
            <div className="about-four-content mb-65 rel z-1 wow fadeInLeft delay-0-2s">
              <div className="section-title mb-50">
                <h2>{t('site_maintenance_title')}</h2>
              </div>
              <Tab.Container defaultActiveKey={"about-tap1"}>
                <Nav as={"ul"} className="nav nav-pills nav-fill mb-35">
                  {siteMaintenance.map(item => (
                    <li className="nav-item" key={item.id}>
                      <Nav.Link
                        as={"a"}
                        className="nav-link"
                        data-bs-toggle="tab"
                        // href="#about-tap2"
                        eventKey={`about-tap${item.id}`}
                      >
                        {t(item.name)}
                      </Nav.Link>
                    </li>
                  ))}
                </Nav>
                <Tab.Content className="tab-content pt-3">
                  {siteMaintenance.map(item => (
                    <Tab.Pane className="tab-pane fade" eventKey={`about-tap${item.id}`} key={item.id}>
                      <p dangerouslySetInnerHTML={{ __html: t(item.text) }}></p>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default SmallBusinessMaintenance