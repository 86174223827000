import Banner from "../components/Banner"
import Team from "../components/Team"
import { useTranslation } from "react-i18next"

const statistics = [
  {
    title: "strategy",
    text: "strategy_text",
    icon: "flaticon-project-management"
  },
  {
    title: "audience",
    text: "audience_text",
    icon: "flaticon-target-audience"
  },
  {
    title: "keyword",
    text: "keyword_text",
    icon: "flaticon-wrench"
  }
]
const About = () => {
  const { t } = useTranslation();

  return (
    <div className="about-page">
      <Banner title="about" />
      <div className="about-us-section sec-mar">
        <div className="container">
          <div className="row">
            <div className="choose-top">
              <div className="row">
                <div
                  className="col-lg-6 wow animate fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
                  <div className="choose-title">
                    <h2 className="text-center text-md-start mb-5">{t('unlock_potencial')}</h2>
                  </div>
                </div>
                <div className="col-lg-6 wow animate fadeInRight">
                  <div className="choose-right-img magnetic-item">
                    <img className="img-fluid" src="/images/about-us.webp" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="choose-btm">
              <div className="row  g-lg-4 gy-5">
                <div className="col-lg-7 wow animate fadeInLeft">
                  <div className="choose-left-content  text-center text-md-start">
                    <h4>{t('about_us_title')}</h4>
                    <p className="p-0" dangerouslySetInnerHTML={{ __html: t('about_us_text') }}></p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="choose-feature">
                    <ul className="p-0">
                      {statistics.map((item, key) => (
                        <li key={key}>
                          <div className="single-feature d-md-flex align-items-center text-center text-md-start wow animate fadeInLeft">
                            <div className="progress mx-auto mb-3 me-md-3">
                              <i className={item.icon}></i>
                            </div>
                            <div className="content">
                              <h4>{t(item.title)}</h4>
                              <p>{t(item.text)}</p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className={`about-seven rel z-1 rpt-50 pb-120 rpb-80 `}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="about-seven-content wow animate fadeInLeft">
                <div className="section-title mb-30  text-center text-md-start">
                  <h2>{t('how_we_do_it')}</h2>
                </div>
                <p dangerouslySetInnerHTML={{ __html: t('how_we_do_text') }} className=" text-center text-md-start"></p>
                <ul className="list-style-three mt-35 mb-50">
                  <li className="d-flex align-items-center"><i className="bi bi-check-circle-fill me-2 mb-3"></i><p>{t('how_we_do_point1')}</p></li>
                  <li className="d-flex align-items-center"><i className="bi bi-check-circle-fill me-2 mb-3"></i><p>{t('how_we_do_point2')}</p></li>
                  <li className="d-flex align-items-center"><i className="bi bi-check-circle-fill me-2 mb-3"></i><p>{t('how_we_do_point3')}</p></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="about-seven-image rmb-60 wow animate fadeInRight">
                <img src='/images/about-section.webp' alt="About" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Team /> */}
    </div>
  )
}
export default About