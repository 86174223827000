import { Link } from "react-router-dom"
import Menu from "./Menu/Menu"
import { useEffect } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();

  const stickyNav = () => {
    window.addEventListener("scroll", () => {
      let offset = window.scrollY;
      const sticky = document.querySelectorAll("header");
      for (let i = 0; i < sticky.length; i++) {
        const stick = sticky[i];
        if (stick) {
          if (offset > 10) {
            stick.classList.add("fixed-header");
          } else {
            stick.classList.remove("fixed-header");
          }
        }
      }
    });
  };

  useEffect(() => {
    stickyNav();
  }, []);

  return (
    <header className="main-header header-three menu-white menu-absolute">
      <div className="header-upper py-2">
        <div className="container clearfix">
          <div className="header-inner rel">
            <div className="nav-outer clearfix m-0">
              <Menu />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header