import Banner from "../components/Banner"
import { useTranslation } from "react-i18next"
import ContactInfo from "../components/ContactForm/ContactFormInfo"
import ErpAbout from "../components/Erp/ErpAbout"
import ErpModules from "../components/Erp/ErpModules"
import ErpCLients from "../components/Erp/ErpClients"

const Product = () => {
  const { t } = useTranslation()

  return (
    <div className="product-page">
      <Banner title="product_erp" bannerClass="erp"/>
      <ErpAbout/>
      <div className="erp-section-two py-50">
        <div className="container">
          <h1 className="text-center wow animate fadeInUp">{t('erp_all_business')}</h1>
        </div>
      </div>
      <ErpModules/>
      <ErpCLients/>
      <ContactInfo />
    </div>
  )
}
export default Product