import { useEffect, useState } from 'react';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Preloader from './components/Preloaded';
import { Route, Routes, useLocation } from 'react-router-dom'
import Contact from './pages/Contact';
import About from './pages/About';
import ScrollToTop from './scroll-to-top';
import ServiceDetails from './pages/ServiceDetails';
import Projects from './pages/Projects';
import ProjectDetails from './pages/ProjectDetails';
import translationSR from './locales/sr.json';
import translationEN from './locales/en.json';
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import WOW from 'wowjs';
import SmallBusinesses from './pages/SmallBusinesses';
import Careers from './pages/Careers';
import JobApply from './pages/JobApply';
import 'swiper/css';
import Outsourcing from './pages/Outsourcing';
import Product from './pages/Product';
import ProductDetails from './pages/ProductDetails';
import { AppsterProvider } from './context';
import "react-multi-carousel/lib/styles.css";

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const resources = {
    sr: {
      translation: translationSR
    },
    eng: {
      translation: translationEN
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined" && !loading) {
      window.WOW = require("wowjs");
    }
    new WOW.WOW().init();
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [loading, location]);

  useEffect(() => {
    i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: "sr",
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
  }, [])


  return (
    <ScrollToTop>
      {loading ? <Preloader /> : (
        <AppsterProvider>
          <Header />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/service-details" element={<ServiceDetails />}></Route>
            <Route path="/projects" element={<Projects />}></Route>
            <Route path="/project-details/:id" element={<ProjectDetails />}></Route>
            <Route path="/small-businesses" element={<SmallBusinesses />}></Route>
            <Route path="/careers" element={<Careers />}></Route>
            <Route path="/job-apply/:id" element={<JobApply />}></Route>
            <Route path="/services" element={<Outsourcing />}></Route>
            <Route path="/erp" element={<Product />}></Route>
            <Route path="/erp/:id" element={<ProductDetails />}></Route>
            <Route path="*" element={<Home />}></Route>
          </Routes>
          <Footer />
        </AppsterProvider>
      )}
    </ScrollToTop>
  );
}

export default App;
