import { useTranslation } from "react-i18next"
import ContactForm from "./ContactForm"
import { useLocation } from "react-router-dom";

const ContactFormInfo = () => {
  const { t } = useTranslation();
  const location= useLocation();
  
  return(
    <section
        className="py-130 rpb-100 rpt-50 bgs-cover">
        <div className="container">
          <div className="section-title text-center mb-40 d-block d-lg-none">
          <h2>{t(location.pathname === "/erp" ? 'more_info' : 'have_a_project')}</h2>
          </div>
          <div className="row gap-100 align-items-center">
            <div className="col-lg-7">
              <ContactForm />
            </div>
            <div className="col-lg-5">
              <div className="contact-info-wrap wow fadeInLeft delay-0-2s ml-25">
                <div className="section-title mb-40 d-none d-lg-block">
                <h2>{t(location.pathname === "/erp" ? 'more_info' : 'have_a_project')}</h2>
                </div>
                <div className="contact-info-part">
                  <div className="contact-info-item">
                    <div className="content row align-items-center">
                      <div className="col-auto">
                        <i className="bi bi-geo-alt-fill"></i>
                      </div>
                      <div className="col">
                        <span>{t('address')}</span>
                        <h6>Mihaila Avramovića 52b</h6>
                      </div>
                    </div>
                  </div>
                  <div className="contact-info-item">
                    <div className="content row align-items-center">
                      <div className="col-auto">
                        <i className="bi bi-envelope-fill"></i>
                      </div>
                      <div className="col">
                        <span>{t('email')}</span>
                        <h6>info@appsterdevelopment.com</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}
export default ContactFormInfo