import { useTranslation } from 'react-i18next';
import smallBusinessPackagesData from '../data/smallBusinessPackagesData.json'
import { Link } from 'react-router-dom';

const Pricing = () => {
  const { t } = useTranslation();

  return (
    <section className="price-plan-area overflow-hidden bgc-lighter pt-130 rpt-100 pb-100 rpb-70 rel z-1">
      <div className="container">
        <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
          <h2>{t('package_price')}</h2>
        </div>
        <div className="row justify-content-center">
          {smallBusinessPackagesData.map((item, key) => (
            <div className="col-xl-4 col-md-6" key={key}>
              <div className={`pricing-plan-item wow fadeInUp delay-0-2s ${item.best && 'active'} h-100`}>
                <h4 className="title">{t(item.name)}</h4>
                <span className="price">{t('from')} {item.price}</span>
                <Link >
                  <a className={`theme-btn style-two ${item.id == 2 ? "bg-white text-primary" : ""}`} >{t('choose_package')}<i className="fas fa-long-arrow-right" /></a>
                </Link>
                <div className="pt-4">
                  {item.included.map((i, key) => (
                    <div className="d-flex" key={key}>
                      <i className="bi bi-check-circle-fill pe-2"></i>
                      <p>{t(i)}</p>
                    </div>
                  ))}
                  {(item.notIncluded || []).map((i, key) => (
                    <div className="d-flex" key={key}>
                      <i className="bi bi-x-circle-fill pe-2"></i>
                      <p>{t(i)}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="price-shapes">
        <img
          className="shape two w-50"
          src="assets/images/shapes/price-right.png"
          alt="Shape"
        />
      </div>
    </section>
  )
}
export default Pricing