import { useTranslation } from 'react-i18next'
import projects from '../data/projectsData.json'

const ProjectsList = () => {
  const { t } = useTranslation();

  return (
    <section className="what-we-provide rel z-1">
      <div className="container">
        <div className='row'>
          {projects.map((item, key) => (
            <div className={`col-12 col-md-6 col-lg-4 service-item-three wow fadeInUp delay-0-${(key + 1) * 2}s mb-3`} key={key}>
              <div className="project-item-two wow fadeInUp delay-0-2s">
                <img src={`/images/projects/${item.image}.webp`} alt="Project Grid" className='w-100 d-none d-md-block' />
                <img src={`/images/projects/${item.image}_small.webp`} alt="Project Grid" className='w-100 d-md-none' />
                <div className="content text-white">
                  <img src={`/images/projects/${item.logo}.png`} alt=""></img>
                  <div dangerouslySetInnerHTML={{ __html: t(item.text)}} className='project-description'></div>
                </div>
              </div>
              <div className="content">
                <div className="top-part text-center">
                  <h4>{item.title}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default ProjectsList