import { useTranslation } from "react-i18next"

const Banner = ({ title, subtitle, bannerClass }) => {
  const { t } = useTranslation();

  return (
    <div children className={`hero-area-two bgs-cover ${bannerClass}`}>
      <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-8">
          <div className="hero-content-two pt-200 pb-50 rpt-115 rpb-20 wow fadeInUp delay-0-4s">
            {subtitle && (<span className="sub-title">{subtitle}</span>)}
            <h1>{t(title)}</h1>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
export default Banner