import Slider from "react-slick";
import { serviceThreeSlider } from '../sliderProps'
import smallBusinessServicesData from '../../data/smallBussinesServicesData.json'
import { useTranslation } from "react-i18next";

const SmallBusinessServices = () => {
  const { t } = useTranslation();
  
  return (
    <section className="what-we-provide overflow-hidden py-130 rpy-100 rel z-1">
      <div className="container">
        <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
          <h2>{t('sm_services_title')}</h2>
        </div>
        <Slider {...serviceThreeSlider} className="service-three-slider">
          {smallBusinessServicesData.map(item => (
            <div className="service-item-three active wow fadeInUp delay-0-4s pe-3 h-100" key={item.id}>
              <div className="image">
                <img src={item.image} alt="" />
              </div>
              <div className="content">
                <div className="top-part">
                  <span className="number">{item.id}</span>
                  <div className="icon">
                    <i className="flaticon-layers" />
                  </div>
                  <h4 className="text-white pt-3">{t(item.title)}</h4>
                </div>
                <div className="bottom-part">
                  <p>{t(item.text)}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="about-bg-shape">
        <img src="assets/images/background/what-we-provide.png" alt="Shape" />
      </div>
    </section>
  )
}
export default SmallBusinessServices